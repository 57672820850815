import React from "react";
import './assets/styles/_index.scss';


const Stepper = (props) => {

    return (
        <div className="stepper">
            <div class="md-stepper-horizontal orange">
                <div class={`md-step ${props.step > 0 ? "done" : ""} ${props.step === 0 || props.step > 0 ? "active" : ""}`}>
                    <div class="md-step-circle"><span>1</span></div>
                    <div class="md-step-title">Find your property</div>
                    <div class="md-step-bar-left"></div>
                    <div class="md-step-bar-right"></div>
                </div>
                <div class={`md-step ${props.step > 1 ? "done" : ""} ${props.step === 1 || props.step > 1 ? "active" : ""}`}>
                    <div class="md-step-circle"><span>2</span></div>
                    <div class="md-step-title">Confirm Details</div>
                    {/* <div class="md-step-optional">Optional</div> */}
                    <div class="md-step-bar-left"></div>
                    <div class="md-step-bar-right"></div>
                </div>
                <div class={`md-step ${props.step > 2 ? "done" : ""} ${props.step === 2 || props.step > 2 ? "active" : ""}`}>
                    <div class="md-step-circle"><span>3</span></div>
                    <div class="md-step-title">Contact Details</div>
                    <div class="md-step-bar-left"></div>
                    <div class="md-step-bar-right"></div>
                </div>
                <div class="md-step">
                    <div class="md-step-circle"><span>4</span></div>
                    <div class="md-step-title">Your Valuation</div>
                    <div class="md-step-bar-left"></div>
                    <div class="md-step-bar-right"></div>
                </div>
            </div>

        </div>
    )
}

export default Stepper