import { Link } from "gatsby";
import React from "react";
import './assets/styles/_index.scss';
const ValuationResult = (props) => {

    return (
        <div className="valuvation-result">
            <p className="valuation-para">Estimated Property Valuation</p>
            <h2 className="amount">AED {props?.valuation?.finalValuation?.toLocaleString()}</h2>
            <hr className="hr" />
            <p className="para-title">Estimated Property Valuation Range</p>
            <p className="range">AED {props?.valuation?.finalValuationLowerEnd?.toLocaleString()} - AED {props?.valuation?.finalValuationHighEnd?.toLocaleString()}</p>
            <p className="desc">Considering similar properties in the area that transferred within the last 6 months, the lowest price transaction was AED {props?.valuation?.finalValuationLowerEnd?.toLocaleString()} and the highest price transaction was AED {props?.valuation?.finalValuationHighEnd?.toLocaleString()}. This Report has been created by Property Monitor Software & Services LLC.</p>
            <p className="report"><a target="_blank" href={props?.valuation?.reportUrl}>Click here</a> to access the full Valuation Report</p>
            {/* <p className="para-report"><Link target="_blank" to={props?.valuation?.reportUrl}>Click here</Link> to download your report</p> */}
        </div>

)

}

export default ValuationResult