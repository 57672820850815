import React from "react"
import DefaultForm from "../forms/default-form-module"
import FormFields from "../../../static/forms/homevisit.json"
import "./HomevistValuation.scss"

const HomevistValuation = ({ data }) => {
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div className="home-vist-valuation-form">
      <DefaultForm homevistEvent fields={FormFields} sourceUrl={pageurl} homevisitData={data}/>
    </div>
  )
}

export default HomevistValuation
