import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import HomevistValuation from "../components/HomevistValuation/HomevistValuation"
import PropertyMonitor from "../components/PropertyMonitor/PropertyMonitor"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GGFXImage from "../modules/GGFXImage"

const Contact = ({ data }, props) => {
  const PageData = data?.strapiPage
  const siteData = data?.strapiSiteConfig
  var imagename = "page.valuation_section_banner_image.banner_image"

  let processedImages = JSON.stringify({})
  if (PageData?.imagetransforms) {
    processedImages =
      PageData?.imagetransforms?.valuation_section_banner_image_Transforms
  }

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  return (
    <Layout
      popUpData={popUpData}
      dark={true}
      popularSearch={PageData?.select_popular_search?.title}
    >
      <div className="layout-padding-top"></div>

      <div className="valuation-page-wrapper">
        <Container>
          <Row>
            <Col xl={12}>
             
              <GGFXImage
                ImageSrc={PageData.banner?.image}
                altText={`${
                  PageData.banner?.image?.alternativeText
                    ? PageData.banner?.image?.alternativeText
                    : PageData?.banner?.title
                    ? PageData?.banner?.title
                    : PageData?.banner?.title
                } banner`}
                imagetransforms={PageData?.ggfx_results}
                renderer="bgImg"
                imagename={imagename}
                strapiID={PageData?.strapi_id}
                className="valuation-banner"
              />

              {PageData?.add_page_modules?.length > 0 &&
                PageData.add_page_modules?.map((module, index) => {
                  return (
                    <>
                      {module.strapi_component ===
                        "page-modules.global-module" &&
                        module.select_module === "instant_valuation_form" && (
                          <PropertyMonitor />
                        )}
                      {module.strapi_component ===
                        "page-modules.global-module" &&
                        module.select_module ===
                          "home_visit_valuation_form" && (
                          <HomevistValuation data={PageData} />
                        )}
                    </>
                  )
                })}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

    >
      
    </SEO>
  )
}
export default Contact

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          ...GlobalModuleFragment
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }
      }
    }
  }
`
